import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./case-card.scss"

import { screenWidthChecker } from "~c/utils/base"

interface Props {
  id: string
  data: {
    frontmatter: {
      description: string
      image: any
      stack: []
      title: string
      slug: string
    }
    html: string
    id: string
  }
  alt?: string
}

const caseCard = ({ id, data, alt = "" }: Props) => {

  const cardShine: any = useRef(null)
  const card: any = useRef(null)

  const d = document,
    bd = d.getElementsByTagName("body")[0],
    htm = d.getElementsByTagName("html")[0]

  const rotateCard = e => {
    let bdst = bd.scrollTop || htm.scrollTop,
      bdsl = bd.scrollLeft,
      pageX = e.pageX,
      pageY = e.pageY,
      elem = e.target,
      offsets = elem.getBoundingClientRect(),
      w = elem.clientWidth || elem.offsetWidth || elem.scrollWidth, // width
      h = elem.clientHeight || elem.offsetHeight || elem.scrollHeight, // height
      wMultiple = 320 / w,
      offsetX = 0.52 - (pageX - offsets.left - bdsl) / w, //cursor position X
      offsetY = 0.52 - (pageY - offsets.top - bdst) / h, //cursor position Y
      dy = pageY - offsets.top - bdst - h / 2, //@h/2 = center of container
      dx = pageX - offsets.left - bdsl - w / 2, //@w/2 = center of container
      yRotate = (offsetX - dx) * (0.07 * wMultiple), //rotation for container Y
      xRotate = (dy - offsetY) * (0.1 * wMultiple), //rotation for container X
      imgCSS = "rotateX(" + xRotate + "deg) rotateY(" + yRotate + "deg)", //img transform
      arad = Math.atan2(dy, dx), //angle between cursor and center of container in RAD
      angle = (arad * 180) / Math.PI - 90 //convert rad in degrees

    card.current.style.transform = `${imgCSS} scale3d(1.07, 1.07, 1.07)`
    cardShine.current.style.backgroundImage = `linear-gradient(${angle}deg, rgba(255,255,255,.25) 0%,rgba(255,255,255,0) 80%)`
  }

  const clearRotationStyles = () => {
    card.current.style.transform = ``
    cardShine.current.style.backgroundImage = ``
  }

  const { image, stack, title, slug } = data.frontmatter

  const imageData = getImage(image)

  return (
    <div
      className={`case-card`}
      id={id}
      itemScope
      itemType="https://schema.org/CreativeWork"
    >
      <div
        className={`case-card__inner`}
        onMouseMove={screenWidthChecker() === 'desktop' ? rotateCard : undefined}
        onMouseLeave={screenWidthChecker() === 'desktop' ? clearRotationStyles : undefined}
      >
        <div className="case-card__overlay" ref={card}>
          <div className="case-card__overlay-tags">
            {stack.map((tech, i) => {
              return (
                <div
                  className={`tag tag--technology tag--${tech} mb-8`}
                  key={i}
                  title={tech}
                ></div>
              )
            })}
          </div>
          <div className="case-card__overlay-title">
            <h3 itemProp="name" title={title}>
              {title}
            </h3>
          </div>
          <div
            className="view-text text-white"
            dangerouslySetInnerHTML={{ __html: data.html }}
            itemProp="description"
          />
          <Link to={`/cases/${slug}`} className="case-card__overlay-link" itemProp="url"> See more </Link>
          <div className="case-card__overlay-shine" ref={cardShine}></div>
        </div>
        <Link to={`/cases/${slug}`} itemProp="url">
          {imageData ? (
            <GatsbyImage
              image={imageData}
              alt={alt}
              imgStyle={{
                objectFit: "contain",
              }}
              itemProp="image"
            />
          ) : null}
        </Link>
      </div>
    </div>
  )
}

export default React.memo(caseCard)
