import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../layouts/layout"
import Breadcrumbs from "~c/partials/breadcrumbs/breadcrumbs"

import { isBrowser } from "~c/utils/base"
import CaseCard from "~c/ui/cards/case-card/case-card"

import "./cases.scss"

interface Props {
  location: {
    hash: string
    host: string
    hostname: string
    href: string
    key: string
    origin: string
    pathname: string
    port: string
    protocol: string
    search: string
    state: string
  }
}

interface Node {
  frontmatter: {
    description: string
    image: any
    stack: []
    title: string
    slug: string
  }
  html: string
  id: string
}

export default ({ location }: Props) => {
  const { allMarkdownRemark } = useStaticQuery(query)

  const caseCards = allMarkdownRemark.nodes.map((node: Node) => {
    return (
      <CaseCard
        data={node}
        alt="NEKO Lighting"
        id={node.id}
        key={node.id}
      ></CaseCard>
    )
  })

  return (
    <Layout
      meta={{
        title: "Best cases",
        description:
          "Best project cases based on my main and secondary stacks: HTML5, CSS3, Javascript, React, Laravel, Wordpress and Node.js, SEO and Server side. ",
        canonical: location.pathname,
      }}
    >
      <div className="view view-cases bubbles">
        <Breadcrumbs location={location} parentClassName="text-center mb-16" />
        <h1 className="visually-hidden"> Best cases </h1>
        <section className="view-cases__list">
          <div className="view-cases__cards">
            {isBrowser ? caseCards : null}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(cases)/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          stack
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData(width: 600, formats: [AUTO, WEBP])
            }
          }
        }
        id
        html
      }
    }
  }
`
